import React, {useEffect, useRef, useState} from 'react';
import {Button, useMediaQuery} from '@mui/material';
import {createTheme, styled} from '@mui/material/styles';
import videoSource from './assets/videos/output.webm';
import '@fontsource/montserrat';
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {motion} from 'framer-motion';
import backgroundJPG from './assets/images/background.jpg';
import backgroundWEBP from './assets/images/background.webp';
import logoImagePNG from './assets/images/logo.png';
import logoImageWEBP from './assets/images/logo.webp';
import videoImagePNG from './assets/images/videoImage.png';
import videoImageWEBP from './assets/images/videoImage.webp';

const GradientButton = styled(Button)({
  background: 'linear-gradient(45deg, #48ffb2 10%, #70bfc2 90%)',
  color: 'white',
  fontSize: '1.2rem',
  height: 48,
  padding: '0 30px',  // Ajuster ici si nécessaire
  whiteSpace: 'nowrap',
  '&:hover': {
    background: 'linear-gradient(45deg, #70bfc2 30%, #48ffb2 90%)',
  },
  width: 'auto',
  minWidth: '0',
});

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif',
    h1: {
      color: '#114860',
      fontSize: '3rem',
      fontWeight: '700',
      '@media (max-width:1280px)': {
        fontSize: '4rem',
      },
      '@media (max-width:960px)': {
        fontSize: '3.5rem',
        '@media (max-height:600px)': {
          fontSize: '1.8rem',
        },
        '@media (min-height:1200px)': {
          fontSize: '3.8rem',
        },
      },
      '@media (max-width:600px)': {
        fontSize: '1.9rem',
      },
      '@media (orientation: landscape)': {
        '@media (max-width:1920px)': {
          fontSize: '4rem',
        },
        '@media (max-width:1280px)': {
          fontSize: '3rem',
        },
        '@media (max-width:960px)': {
          fontSize: '2rem',
        },
        '@media (max-width:600px)': {
          fontSize: '1.9rem',
        },
      },
    },
    h2: {
      color: '#114860',
      fontSize: '1.6rem',
      fontWeight: '700',
      '@media (max-width:1280px)': {
        fontSize: '2.5rem',
      },
      '@media (max-width:960px)': {
        fontSize: '1.8rem',
        '@media (min-height:1200px)': {
          fontSize: '2.3rem',
        },
      },
      '@media (max-width:600px)': {
          fontWeight: '800',
          fontSize: '1rem',
          '@media (min-height:800px)': {
            fontWeight: '800',
            fontSize: '1.3rem',
          },
        },
      '@media (orientation: landscape)': {
        '@media (max-width:1920px)': {
          fontSize: '2rem',
        },
        '@media (max-width:1280px)': {
          fontSize: '2rem',
        },
        '@media (max-width:960px)': {
          fontSize: '1.3rem',
        },
        '@media (max-width:600px)': {
          fontSize: '1rem',
        },
      }
    },
    h3: {
      color: '#07b8ba',
      fontSize: '2rem',
      fontWeight: '700',
      '@media (max-width:1280px)': {
        fontSize: '3rem',
      },
      '@media (max-width:960px)': {
        fontSize: '2rem',
        '@media (min-height:1200px)': {
          fontSize: '2.5rem',
        },
      },
      '@media (max-width:600px)': {
        fontSize: '1.2rem',
      },
      '@media (orientation: landscape)': {
        '@media (max-width:1920px)': {
          fontSize: '2rem',
        },
        '@media (max-width:1280px)': {
          fontSize: '1.6rem',
        },
        '@media (max-width:960px)': {
          fontSize: '1.2rem',
          '@media (max-height:400px)': {
            fontSize: '1rem',
          },
        },
        '@media (max-width:700px)': {
          fontSize: '1rem',
        },
      },
    },
    body1: {
      color: '#70bfc2',
      fontSize: '1.2rem',
      fontWeight: '600',
      
      '@media (max-width:1280px)': {
        fontSize: '2rem',
      },
      '@media (max-width:960px)': {
        fontSize: '1.5rem',
        fontWeight: '600',
        '@media (min-height:1200px)': {
          fontSize: '1.8rem',
        },
      },
      '@media (max-width:600px)': {
        fontSize: '1rem',
        fontWeight: '600',
        '@media (min-height:750px)': {
          fontSize: '1.2rem',
        },
      },
      '@media (orientation: landscape)': {
        fontSize: '1.2rem',
        '@media (max-width:1920px)': {
          fontSize: '1.5rem',
        },
        '@media (max-width:1280px)': {
          fontSize: '1.5rem',
          '@media (max-height:700px)': {
            fontSize: '1.3rem',
          },
        },
        '@media (max-width:960px)': {
          fontSize: '1rem',
          fontWeight: '600',
        },
        '@media (max-width:900px)': {
          fontSize: '1rem',
          fontWeight: '600',
          '@media (max-height:400px)': {
            fontSize: '0.9rem',
          },
        },
        '@media (max-width:750px)': {
          fontSize: '0.8rem',
          fontWeight: '600',
        },
        '@media (max-width:600px)': {
          fontSize: '0.7rem',
          fontWeight: '600',
        },
      },
    },
    body2: {
      color: '#48ffb2',
      fontWeight: '500',
      '@media (max-width:960px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width:60px)': {
        fontSize: '1rem',
      },
    },
    body3: {
      color: '#70bfc2',
      fontWeight: '600',
      fontSize: '1rem',
      '@media (max-width:1280px)': {
        fontSize: '1.8rem',
      },
      '@media (max-width:960px)': {
        fontSize: '1.3rem',
        fontWeight: '600',
        '@media (min-height:1300px)': {
          fontSize: '1.6rem',
        },
      },
      '@media (max-width:600px)': {
        fontSize: '0.7rem',
        fontWeight: '600',
        '@media (min-height:600px)': {
          fontSize: '0.9rem',
          fontWeight: '600',
        },
      },
      '@media (orientation: landscape)': {
        '@media (max-width:1280px)': {
          fontSize: '1.2rem',
        },
        '@media (max-width:960px)': {
          fontSize: '0.8rem',
          fontWeight: '600',
        },
        '@media (max-width:750px)': {
          fontSize: '0.7rem',
          fontWeight: '600',
        },
        '@media (max-width:600px)': {
          fontSize: '0.6rem',
          fontWeight: '600',
        },
      },
    },
  },
});

const containerVariants = {
  hidden: {opacity: 0},
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    }
  }
};

const itemVariants = {
  hidden: {opacity: 0},
  visible: {
    opacity: 1,
    transition: {
      duration: 1.6
    }
  }
};

//Couleurs du thèmes : #114860 #48ffb2 #bdf2fb #70bfc2 #07b8ba
function App() {
  const videoRef = useRef(null);
  const [pauseVideo, setPauseVideo] = useState(false);
  const [supportsWebP, setSupportsWebP] = useState(null);
  const [videoVisible, setVideoVisible] = useState(false);
  const isLandscapeMode = useMediaQuery('(orientation: landscape)');
  // MediaQuery mobile
  const isShortScreen = useMediaQuery('(max-height:500px)');
  const isLongScreen = useMediaQuery('(min-height:700px) and (max-width:500px)');
  // MediaQuery tablette
  const isLongTablet = useMediaQuery('(min-height:1100px) and (max-width:960px)');
  //Largeur d'écran
  const isExtraSmallScreen = useMediaQuery('(max-width:600px)');
  const isSmallScreen = useMediaQuery('(max-width:960px)');

  //Video handling
  useEffect(() => {
    const video = videoRef.current;
    const options = {
      root: null, //in the viewport
      rootMargin: '0px',
      threshold: 0.1 //loading when 10% of the image is visible
    }
  
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting && videoVisible){
            handleVideoPlay();
            observer.unobserve(video);
          }
        })
    }, options);
    
    if(video){
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  },[videoVisible]);
  

// Fonction pour gérer la reprise de la vidéo
  const handleVideoPlay = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0.1; // Revenir à 0.1 seconde dans la vidéo
      video.play().catch(error => {
        console.error("Error playing video: ", error);
        setPauseVideo(false); // Éviter de retenter la lecture si une erreur est survenue
      });
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    const handleTimeUpdate = () => {
      // Si la vidéo est à moins de 2.5 secondes de la fin
      if (video.duration - video.currentTime < 2.5) {
        video.pause();
        setPauseVideo(true);
      }
    };

    if(video){
      video.addEventListener('timeupdate', handleTimeUpdate);
    }
  
    return () => {
      // Nettoyage de l'écouteur d'événements
      if(video){
        video.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    if (pauseVideo) {
      // Définir un délai avant de redémarrer la vidéo
      const timer = setTimeout(() => {
        handleVideoPlay();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [pauseVideo]);

  useEffect(() => {
    const supportWebP = () => {
      const canvas = document.createElement('canvas');
      // On vérifie si le navigateur peut dessiner sur un canvas
      if (!!(canvas.getContext && canvas.getContext('2d'))) {
        // Vérifie si le navigateur peut encoder en WebP
        return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
      }
      return false;
    }
    setSupportsWebP(supportWebP());
  },[]);

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Box sx={{
          position: 'relative',
          width: '100vw',
          minHeight: '100vh',
          maxHeight: {xs: '100vh'},
          overflowX: 'hidden',
          overflowY: 'hidden',
          backgroundImage: supportsWebP ? `url(${backgroundWEBP})` : `url(${backgroundJPG})`,
          backgroundSize: {xs: 'cover', lg: '100% 100%'},
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}>
          <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: isLandscapeMode ? 'row' : 'column',
                  }}>
            <Box sx={{
              position: isLandscapeMode ? 'absolute' : {xs:'relative', lg: 'absolute'},
              top: isLandscapeMode ? 0 : {xs: '10px', sm: '15px', md: '0'},
              left: 0,
              width: {xs: '100%', sm: '100%', lg: '50%'},
              padding: isLandscapeMode ? {xs: '0 10px', sm: '3vh 3vw', md:'2% 3%', xl: '2% 5%'} 
              : {xs: '0 10px',  sm:'2% 5%', md:'2% 4%' ,lg: '2% 3%', xl: '2% 5%'},
              zIndex: 3,
              height: isLandscapeMode ? '100vh': '70vh',
              maxWidth: isLandscapeMode ? '60vw' : '100%'
            }}>
              <motion.div variants={itemVariants} style={{
                    height: 'fit-content',
                    maxHeight: isLandscapeMode ? '100%': 'none',
                    marginBottom: isLandscapeMode ? 
                                    (isExtraSmallScreen ? '10px' 
                                     : isSmallScreen ? (isShortScreen ? '10px' : '20px') 
                                      : '20px')
                                  : (isExtraSmallScreen ? 
                                      (isLongScreen ? '20px' :'10px') 
                                     : '20px'),
                }}>
                <Box component='header' sx={
                  {display: 'flex', justifyContent: isLandscapeMode ? 'left' : 'center', 
                  alignItems: 'center', 
                  height: 'fit-content',
                  }}>
                    <picture style={{
                        width: 'fit-content',
                        height: '100%',
                        marginRight: '20px'
                      }}>
                      <source srcSet={logoImageWEBP} type="image/webp"/>
                      <img src={logoImagePNG} alt="DigiFactory SA Logo"
                        style={{
                          width: 'auto', height: '100%', 
                          maxHeight: '15vh', 
                          maxWidth: isLandscapeMode ? '20vw' : isExtraSmallScreen ? '20vw' : isSmallScreen ? '16vw' : '15vw',
                        }}/>
                    </picture>
                  <Box style={{maxWidth: isLandscapeMode ?  'none' : '70%'}}>
                      <Typography variant="h1" sx={{
                          marginLeft: '-3px',
                          marginBottom: '5px',
                          width: '100vw',
                          maxWidth : isLandscapeMode ? 'none' : 'fit-content',
                        }}>
                          DigiFactory SA
                      </Typography>
                      <Typography variant="h2" sx={{
                        width: '100vw',
                        maxWidth : isLandscapeMode ? 'none' : '70vw',
                      }}>
                          Développement logiciel à Genève
                      </Typography>
                  </Box>
                </Box>
              </motion.div>
              <Box component='main' sx={{
                margin: {
                  xs: '0 5px',
                  lg: '0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
                }}>
                <motion.div variants={itemVariants}>
                  <Typography variant="h3" sx={{mb: isLandscapeMode ? {xs:'10px', lg:3} : {xs: '10px', md: '20px', lg: 5}, textAlign: isLandscapeMode? 'left': 'center'}}>
                    Notre site est en maintenance pour améliorer votre expérience utilisateur.</Typography>
                </motion.div>
                <motion.div variants={itemVariants} style={ 
                    {margin : isLandscapeMode ? 0 
                        :isExtraSmallScreen ? '0 5px' 
                        : isSmallScreen ? '0 20px'
                        : '0',}
                  }>
                  <Typography variant="body1" sx={{
                      mb: isLandscapeMode ? {xs: '10px', lg: 3} : {xs: '10px', sm: '20px'}, 
                      textAlign: isLandscapeMode ? 'left' : 'center',
                      width: '95%',
                    }}>
                    Nous travaillons dur pour vous offrir des solutions innovantes et performantes.
                  </Typography>
                  <Typography variant="body1" sx={{
                      mb: isLandscapeMode ? {xs: '10px', lg: 3} : isLongScreen ? '15px' : {xs: '10px', sm: '20px'},
                      textAlign: isLandscapeMode ? 'left' : 'center',
                      width: '95%',
                    }}>
                    Basée à Genève, DigiFactory SA s'engage à fournir des services de développement logiciel de qualité supérieure, 
                    répondant à vos besoins numériques.
                  </Typography>
                </motion.div>
                <motion.div variants={itemVariants} style={{width: '100%'}}>
                  <Box sx={{
                    textAlign: isLandscapeMode ? 'left' : 'center',
                    }}>
                      <GradientButton aria-label='Envoyer un email à DigiFactory'
                        component="a"
                        href="mailto:contact@digifactory-sa.ch?subject=Demande de Contact"
                        sx={{alignContent: 'center', zIndex: 3, 
                              height: isLandscapeMode ? {xs: '10vh', lg:'8vh'} : {xs: '6vh', sm: '8vh'},
                              width : 'fit-content',
                              fontWeight : 800,
                              fontSize : isLandscapeMode ? {xs: '0.8rem', md: '1.5rem'} : {xs: '1rem', sm: '1.5rem'},
                            }}>
                          Contactez-nous
                      </GradientButton>
                  </Box>
                </motion.div>
              </Box> {/* main */}
              <Box component='footer' sx={{margin: '0 5px'}}>
                <motion.div variants={itemVariants} style={{width: '100%'}}>
                  <Typography variant="body3" 
                    sx={{
                      position: 'relative',
                      bottom: 0,
                      width: '100%',
                      height: '100%',
                      textAlign: isLandscapeMode ? 'left' : 'center',
                      zIndex: 3,
                      display : isShortScreen ? 'block' : {xs: 'block', lg: 'none'},
                      margin: isLandscapeMode ? '5px 0 0 0' 
                              : isLongScreen ? '8px 0 0 0' 
                              : isLongTablet ? '12px 0 0 0'
                              : '3px 0 0 0',
                    }}>
                    Quai Gustave-Ador 18, 1207 Genève, Suisse <br/> DigiFactory SA 2021-2024
                  </Typography>
                </motion.div>
              </Box>
            </Box>
          <motion.div 
            variants={itemVariants} 
            onAnimationComplete={() => setVideoVisible(true)}>
            <Box sx={{
              position: isLandscapeMode ? 'absolute' : 'relative',
              bottom: 0,
              right: isLandscapeMode ? {xs: '-15%', md: '-18%'}: 0,
              width: isLandscapeMode ? {xs: '110%', lg: '100%'} : {xs: isLongScreen ? '60vh':'55vh', sm: '70vh'},
              height: {xs: '50vh', lg: '100vh'},
              maxWidth: isLandscapeMode ? {xs: '70vw', md: '75vw', lg: '80vw'} : 'none', 
              maxHeight: isLandscapeMode ? '100%' : '32vh',
              objectFit: 'cover',
              zIndex: 3,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}>
              <video ref={videoRef}
                muted 
                playsInline 
                preload='none'
                poster={supportsWebP ? videoImageWEBP : videoImagePNG}
                style={{width: '100%', height: 'auto'}}>
                <source src={videoSource} type="video/webm"/>
              </video> 
            </Box>
          </motion.div>
          <motion.div variants={itemVariants}>
          <Box component='footer' sx={{
            position: 'absolute',
            bottom: {xs: 0, sm: 10},
            width: '100%',
            textAlign: 'center',
            zIndex: 3,
            display : isShortScreen ? 'none' : {xs: 'none', lg: 'block'}
          }}>
            <Typography variant="body2">
              DigiFactory SA 2021-2024<br/>Quai Gustave-Ador 18, 1207 Genève, Suisse  
            </Typography>
          </Box>
          </motion.div>
          </motion.div>
        </Box>
        
      </ThemeProvider>
  );
}

export default App;
